// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-field-trip-js": () => import("./../src/templates/field-trip.js" /* webpackChunkName: "component---src-templates-field-trip-js" */),
  "component---src-templates-field-trips-js": () => import("./../src/templates/field-trips.js" /* webpackChunkName: "component---src-templates-field-trips-js" */),
  "component---src-templates-page-js": () => import("./../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */)
}

